import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function Login({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Проверка локального хранилища при первом рендере
    useEffect(() => {
        const savedUsername = localStorage.getItem('username');
        const savedPassword = localStorage.getItem('password');

        if (savedUsername && savedPassword) {
            // Если логин и пароль есть, автоматически выполняем вход
            setUsername(savedUsername);
            setPassword(savedPassword);
            console.log('handleLogin', savedUsername, savedPassword)
            handleLogin(savedUsername, savedPassword); // Вызываем функцию логина
        }
    }, []);

    const handleLogin = (user = username, pass = password) => {
        console.log('handleLogin', username, password)
        if (typeof user !== 'string' || typeof pass !== 'string') {
            console.error('Username and password must be strings');
            user = username
            pass = password
        }
        axios.post(`${API_URL}/api/login`, {
            username: user,
            password: pass
        })
            .then(response => {
                console.log('Login success', response.data);
                localStorage.setItem('username', user); // Сохраняем логин в localStorage
                localStorage.setItem('password', pass); // Сохраняем пароль в localStorage
                onLoginSuccess();
            })
            .catch(error => {
                console.error('There was an error logging in!', error);
                setError('Login failed. Please check your credentials and try again.');
            });
    };

    return (
        <div>
            <h2>Login</h2>
            <div>
                <label>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleLogin}>Login</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
}

export default Login;
